import { React, Normalize, styled, PropTypes } from '../shipityo'
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"

const StyleWrapper = styled.div`
  margin: 0px auto;
  max-width: 960px;
  padding-top: 0px;
`;

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <Normalize>
        <StyleWrapper className="main-wrapper">
          <Header siteTitle={data.site.siteMetadata.title} />
          <div>
            <main>{children}</main>
            <footer>
              © {new Date().getFullYear()}
            </footer>
          </div>
        </StyleWrapper>
      </Normalize>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
